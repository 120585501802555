<template>
    <v-row>
        <v-col cols="12" sm="6" offset-sm="3" md="8" offset-md="2">
            <v-card class="display-block" elevation="15">
                <h3>Plan Details</h3>
                <v-card-text>
                    <alert-message v-if="msg" :error="msg"></alert-message>
                    <v-form ref="form" lazy-validation>
                        <v-row align="center">
                            <v-col cols="12">
                                <v-text-field label="Name*" :rules="nameRequired" v-model="plan.title"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Interval*" type="number" :rules="fieldRequired"
                                    v-model.number="plan.interval" required :disabled="id != 0"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete label="Frequency*" :items="frequencyList" :rules="fieldRequired"
                                    v-model.number="plan.frequency" required :disabled="id != 0"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Trial Days" type="number"
                                    v-model.number="plan.trialDays"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Price*" type="number" :rules="fieldRequired"
                                    v-model.number="plan.amount" required :disabled="id != 0"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Offer Percent" type="number"
                                    v-model.number="plan.offerPercent"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete label="Allowed Modules*" :items="allowedModulesList" item-text="title"
                                    item-value="code" :rules="fieldRequired" v-model="plan.allowedModules"
                                    multiple></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-switch :label="`${plan.active ? 'ACTIVE' : 'DISABLED'}`" v-model="plan.active">{{
                                    plan.active }}</v-switch>
                            </v-col>
                        </v-row>
                        <v-flex text-right>
                            <v-btn class="mr-4" :style="theme" :loading="loading" :disabled="loading"
                                @click="submit()">Save</v-btn>
                            <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                        </v-flex>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import appConstants from '@/utils/appConstants'
import moment from 'moment'
export default {
    data() {
        return {
            plan: {
                id: "",
                title: "",
                amount: 0,
                description: "",
                interval: 1,
                frequency: appConstants.BILLING_FREQUENCY.MONTHLY,
                trialDays: 0,
                offerPercent: 0,
                allowedModules: ['basic'],
                active: true
            },
            frequencyList: Object.values(appConstants.BILLING_FREQUENCY),
            allowedModulesList: [],
            items: [],
            id: 0,
            loading: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.loading = true
            try {
                this.allowedModulesList = await this.getItem(appConstants.MODULES_API)
                this.id = this.$route.params.id
                if (this.id != 0) {
                    this.plan = await this.getItem(appConstants.PLANS_API + "/" + this.id)
                }
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
            this.loading = false
        },

        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true
                if (this.id == 0) {
                    this.saveItem()
                } else {
                    this.updateItem()
                }
            }
        },
        async saveItem() {
            try {
                await this.postItem(appConstants.PLANS_API, this.plan)
                this.$router.push({
                    name: "Plans"
                })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            } finally {
                this.loading = false
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.PLANS_API + "/" + this.plan._id, this.plan)
                this.$router.push({
                    name: "Plans"
                })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
        }
    },
}
</script>

<style scoped></style>
